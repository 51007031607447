import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/utils/Seo'
import News from '../components/pages/News'

export const NEWS_TILES_QUERY = graphql`
  query NewsTilesQuery($firstTileSlug: String!, $lastTileSlug: String!) {
    tiles: allWpTile(
      limit: 1000
      filter: { slug: { in: [$firstTileSlug, $lastTileSlug] } }
    ) {
      ...allTilesFragment
    }
    news: allWpNewsItem(
      limit: 1000
      filter: { slug: { ne: "donotdelete" } }
      sort: { fields: date, order: DESC }
    ) {
      ...newsFragment
    }
  }
`

export const newsFragment = graphql`
  fragment newsFragment on WpNewsItemConnection {
    edges {
      node {
        id
        title
        slug
        content
        newsType {
          link
          select
        }
        enDate: date(formatString: "MMMM DD, YYYY")
        deDate: date(formatString: "DD.MM.YYYY")
        featuredImage {
          node {
            altText
            caption
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

const NewsPage = (props) => {
  return (
    <>
      <Seo title="News" {...props.international} />
      <News {...props} />
    </>
  )
}

export default NewsPage
